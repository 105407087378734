import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TenantConfigService } from './general/services/tenant-config.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WebSocketsService } from './general/services/web-sockets/web-sockets.service';
import { UsersStoreService } from './general/services/users-store.service';
import { IconsComponent } from '@dispo-shared/shared-ui';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { BuildInfoService } from './general/services/build-info/build-info.service';

@Component({
  selector: 'xyz-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, IconsComponent],
})
export class AppComponent implements OnInit {
  private buildInfoService = inject(BuildInfoService);

  private titleService = inject(Title);
  private tenantService = inject(TenantConfigService);
  private socketService = inject(WebSocketsService);
  private userStoreService = inject(UsersStoreService);
  private translateService = inject(TranslateService);
  private primeNGConfig = inject(PrimeNGConfig);

  favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  constructor() {
    // Set title
    this.titleService.setTitle('Tourfold | ' + this.tenantService.tenantConfig().pageTitle || '');

    // Set favicon
    if (this.favIcon) {
      console.log(this.tenantService.tenantConfig().favicon);
      if (this.tenantService.tenantConfig().favicon !== '') {
        this.favIcon.href = this.tenantService.tenantConfig().favicon;
      } else {
        this.favIcon.href = `./assets/img/favicons/tourfold.png`;
      }
    }

    // Set colors
    const colors = this.tenantService.tenantConfig().colors;
    for (const key in colors) {
      if (colors[key]) {
        document.documentElement.style.setProperty(key, colors[key]);
      }
    }

    // set connected status and store it storeService
    this.socketService.connectionSubject.pipe(takeUntilDestroyed()).subscribe((val) => {
      this.userStoreService.setConnected(val);
    });

    // Log build info to console
    console.log('Build id:', this.buildInfoService.buildId());
  }
  ngOnInit(): void {
    this.primeNGConfig.setTranslation({
      dayNames: this.translateService.instant('SHARED.DATE.WEEKDAYS'),
      dayNamesMin: this.translateService.instant('SHARED.DATE.WEEKDAYS_SHORT'),
      dayNamesShort: this.translateService.instant('SHARED.DATE.WEEKDAYS_SHORT'),
      monthNames: this.translateService.instant('SHARED.DATE.MONTHS'),
      monthNamesShort: this.translateService.instant('SHARED.DATE.MONTHS_SHORT'),
    });
  }
}
