/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Case } from '../../models/case';
import { FinishCaseRequest } from '../../models/finish-case-request';

export interface FinishCase$Params {

/**
 * Id of a resource.
 */
  id: string;
      body: FinishCaseRequest
}

export function finishCase(http: HttpClient, rootUrl: string, params: FinishCase$Params, context?: HttpContext): Observable<StrictHttpResponse<Case>> {
  const rb = new RequestBuilder(rootUrl, finishCase.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Case>;
    })
  );
}

finishCase.PATH = '/api/v1/extensions/tenants/oeamtc/cases/{id}/finish';
