/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Case } from '../models/case';
import { CaseAssetResponse } from '../models/case-asset-response';
import { CaseCommentListResponse } from '../models/case-comment-list-response';
import { CaseListResponse } from '../models/case-list-response';
import { CaseStateUpdatesListResponse } from '../models/case-state-updates-list-response';
import { Comment } from '../models/comment';
import { createCase } from '../fn/cases/create-case';
import { CreateCase$Params } from '../fn/cases/create-case';
import { createCaseComment } from '../fn/cases/create-case-comment';
import { CreateCaseComment$Params } from '../fn/cases/create-case-comment';
import { getCase } from '../fn/cases/get-case';
import { GetCase$Params } from '../fn/cases/get-case';
import { getCaseComments } from '../fn/cases/get-case-comments';
import { GetCaseComments$Params } from '../fn/cases/get-case-comments';
import { getCases } from '../fn/cases/get-cases';
import { GetCases$Params } from '../fn/cases/get-cases';
import { getCaseStateHistory } from '../fn/cases/get-case-state-history';
import { GetCaseStateHistory$Params } from '../fn/cases/get-case-state-history';
import { receiveAction } from '../fn/cases/receive-action';
import { ReceiveAction$Params } from '../fn/cases/receive-action';
import { setCaseTodoCompleted } from '../fn/cases/set-case-todo-completed';
import { SetCaseTodoCompleted$Params } from '../fn/cases/set-case-todo-completed';
import { updateCase } from '../fn/cases/update-case';
import { UpdateCase$Params } from '../fn/cases/update-case';
import { uploadCaseAsset } from '../fn/cases/upload-case-asset';
import { UploadCaseAsset$Params } from '../fn/cases/upload-case-asset';

@Injectable({ providedIn: 'root' })
export class CasesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCase()` */
  static readonly CreateCasePath = '/api/v1/cases';

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCase$Response(params: CreateCase$Params, context?: HttpContext): Observable<StrictHttpResponse<Case>> {
    return createCase(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCase(params: CreateCase$Params, context?: HttpContext): Observable<Case> {
    return this.createCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<Case>): Case => r.body)
    );
  }

  /** Path part for operation `getCases()` */
  static readonly GetCasesPath = '/api/v1/cases-request';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCases()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCases$Response(params: GetCases$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseListResponse>> {
    return getCases(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCases$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCases(params: GetCases$Params, context?: HttpContext): Observable<CaseListResponse> {
    return this.getCases$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseListResponse>): CaseListResponse => r.body)
    );
  }

  /** Path part for operation `setCaseTodoCompleted()` */
  static readonly SetCaseTodoCompletedPath = '/api/v1/cases/{case_id}/todos/{todo_id}/set_completed';

  /**
   * Set todo completed flag to true or false.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCaseTodoCompleted()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCaseTodoCompleted$Response(params: SetCaseTodoCompleted$Params, context?: HttpContext): Observable<StrictHttpResponse<Case>> {
    return setCaseTodoCompleted(this.http, this.rootUrl, params, context);
  }

  /**
   * Set todo completed flag to true or false.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCaseTodoCompleted$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCaseTodoCompleted(params: SetCaseTodoCompleted$Params, context?: HttpContext): Observable<Case> {
    return this.setCaseTodoCompleted$Response(params, context).pipe(
      map((r: StrictHttpResponse<Case>): Case => r.body)
    );
  }

  /** Path part for operation `getCase()` */
  static readonly GetCasePath = '/api/v1/cases/{id}';

  /**
   * Get resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCase()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCase$Response(params: GetCase$Params, context?: HttpContext): Observable<StrictHttpResponse<Case>> {
    return getCase(this.http, this.rootUrl, params, context);
  }

  /**
   * Get resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCase$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCase(params: GetCase$Params, context?: HttpContext): Observable<Case> {
    return this.getCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<Case>): Case => r.body)
    );
  }

  /** Path part for operation `updateCase()` */
  static readonly UpdateCasePath = '/api/v1/cases/{id}';

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCase$Response(params: UpdateCase$Params, context?: HttpContext): Observable<StrictHttpResponse<Case>> {
    return updateCase(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCase(params: UpdateCase$Params, context?: HttpContext): Observable<Case> {
    return this.updateCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<Case>): Case => r.body)
    );
  }

  /** Path part for operation `uploadCaseAsset()` */
  static readonly UploadCaseAssetPath = '/api/v1/cases/{id}/assets';

  /**
   * upload assets for specific case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadCaseAsset()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCaseAsset$Response(params: UploadCaseAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseAssetResponse>> {
    return uploadCaseAsset(this.http, this.rootUrl, params, context);
  }

  /**
   * upload assets for specific case.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadCaseAsset$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCaseAsset(params: UploadCaseAsset$Params, context?: HttpContext): Observable<CaseAssetResponse> {
    return this.uploadCaseAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseAssetResponse>): CaseAssetResponse => r.body)
    );
  }

  /** Path part for operation `getCaseComments()` */
  static readonly GetCaseCommentsPath = '/api/v1/cases/{id}/comment';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseComments$Response(params: GetCaseComments$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseCommentListResponse>> {
    return getCaseComments(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCaseComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseComments(params: GetCaseComments$Params, context?: HttpContext): Observable<CaseCommentListResponse> {
    return this.getCaseComments$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseCommentListResponse>): CaseCommentListResponse => r.body)
    );
  }

  /** Path part for operation `createCaseComment()` */
  static readonly CreateCaseCommentPath = '/api/v1/cases/{id}/comment';

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCaseComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCaseComment$Response(params: CreateCaseComment$Params, context?: HttpContext): Observable<StrictHttpResponse<Comment>> {
    return createCaseComment(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCaseComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCaseComment(params: CreateCaseComment$Params, context?: HttpContext): Observable<Comment> {
    return this.createCaseComment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Comment>): Comment => r.body)
    );
  }

  /** Path part for operation `receiveAction()` */
  static readonly ReceiveActionPath = '/api/v1/cases/{id}/send_action';

  /**
   * Send action to a specific case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receiveAction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receiveAction$Response(params: ReceiveAction$Params, context?: HttpContext): Observable<StrictHttpResponse<Case>> {
    return receiveAction(this.http, this.rootUrl, params, context);
  }

  /**
   * Send action to a specific case.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receiveAction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receiveAction(params: ReceiveAction$Params, context?: HttpContext): Observable<Case> {
    return this.receiveAction$Response(params, context).pipe(
      map((r: StrictHttpResponse<Case>): Case => r.body)
    );
  }

  /** Path part for operation `getCaseStateHistory()` */
  static readonly GetCaseStateHistoryPath = '/api/v1/cases/{id}/state_history';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseStateHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseStateHistory$Response(params: GetCaseStateHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseStateUpdatesListResponse>> {
    return getCaseStateHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCaseStateHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseStateHistory(params: GetCaseStateHistory$Params, context?: HttpContext): Observable<CaseStateUpdatesListResponse> {
    return this.getCaseStateHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseStateUpdatesListResponse>): CaseStateUpdatesListResponse => r.body)
    );
  }

}
