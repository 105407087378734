import { Injectable, signal } from '@angular/core';
import BuildInfoJson from '../../../../assets/build.info.json';

@Injectable({
  providedIn: 'root',
})
export class BuildInfoService {
  public buildId = signal<string>('');
  public buildVersion = signal<string>('');

  constructor() {
    // Build id
    this.buildId.set(BuildInfoJson.build_id);

    // Version
    let buildVersion = BuildInfoJson.version;
    console.log('Build version:', buildVersion);
    // If buildVersion is null or an empty string, set it to build_id
    if (buildVersion !== null || buildVersion !== '') {
      // Check if buildVersion contains 'release-' and remove it
      if (buildVersion.includes('release-')) {
        buildVersion = buildVersion.replace('release-', '');
      }
      this.buildVersion.set(buildVersion);
    } else {
      this.buildVersion.set(BuildInfoJson.build_id);
    }
  }
}
